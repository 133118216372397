import { belowBreakpointOrEqual } from 'components/ContentAggregation/ListContentPost';
import { sizeConditional, SizeConditionalComponent } from 'components/General/SizeConditional/SizeConditional';
import ImgI18N from 'components/Utils/img-i18n';
import { appendPx } from 'imaginarity-react-ui';
import * as _ from "lodash";
import * as React from 'react';
import { connect } from 'react-redux';
import { Button, ButtonGroup, Flag, FlagNameValues, Icon, Modal, SemanticICONS } from 'semantic-ui-react';
import { ActionCreators } from 'services/Actions';
import { config } from 'services/Config';
import { splitText, splitTextToPara } from 'services/Helpers';
import { IConnectedComponent, IState } from 'services/Interfaces';
import { store } from 'services/Reducer';
import styled from 'styled-components';

export interface PluginLandingPageProps extends SizeConditionalComponent, IConnectedComponent {
}

export interface PluginLandingPageState {
    legalOpen: boolean;
    languageOpen: boolean;
    selectedLang: string;
    link?: string;
}

export const OuterContainer = styled.div`
    position: relative;
    background-color: #000000;
    margin:auto;
    min-height:100vh;
`;
export const InnerContainerTop = styled.div<{ mobile: boolean }>`
    width:100%;
    background-color: #000000;
    background-image: url("https://cdn.imaginarity.com/public/images/pcm_top2.jpg"), url("https://cdn.imaginarity.com/public/images/pcm_0.png");
    background-repeat: no-repeat, repeat-x;
    background-position: ${props => props.mobile ? "right bottom" : "right center"}, left bottom;
    -webkit-background-size:contain;
    -moz-background-size:contain;
    -o-background-size:contain;
    background-size:contain;
    padding: 98px 50px;
`;
export const InnerContainerTopContent = styled.div`
    width:100%;
    max-width:1100px;
    margin:auto;
    color: #FFFFFF;
`;
export const Legal = styled.div`
    font-size:1.2rem;
    width: 100%;
    text-align: right;
    margin-top:-60px;
    padding-right:3%;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    &:hover{
        color: #950014;
    }
`;
export const HeadlineTop = styled.div<{ mobile: boolean }>`
    font-size:${props => props.mobile ? "2rem" : "3rem"};
    line-height:${props => props.mobile ? "2rem" : "3rem"};
    width:${props => props.mobile ? "100%" : "540px"};
    max-width:1100px;
    padding-top:${props => props.mobile ? "20px" : "50px"};
`;
export const ContentTop = styled.div<{ mobile: boolean }>`
    padding-top:30px;
    font-size:1.1rem;
    line-height:1.3rem;
    width:${props => props.mobile ? "100%" : "540px"};
    max-width:1100px;
`;
export const InnerContainerBottom = styled.div<{ mobile: boolean }>`
    min-height:${props => props.mobile ? "100vh" : "50vh"};
    background-attachment: ${props => props.mobile ? "fixed" : "scroll"};
    width:100%;
    overflow-x: hidden;
    background-color: #000000;
    background-image: url("https://cdn.imaginarity.com/public/images/pcmbottom.jpg");
    background-position: top center;
    background-repeat: no-repeat;
    -webkit-background-size:cover;
    -moz-background-size:cover;
    -o-background-size:cover;
    background-size:cover;
    text-align: center;
    padding-top: 0px;
    padding-left:50px;
    padding-right:50px;
    padding-bottom: ${props => appendPx(props.mobile ? 50 : 20)};
`;

export const InnerContainerBottomContent = styled.div<{ mobile: boolean }>`
    max-width:1100px;
    margin:0 auto;
    color: #FFFFFF;
    display: grid;
    grid-gap: 1rem;
    grid-auto-rows: 1fr;
    grid-template-columns: repeat(${props => props.mobile ? 1 : 2}, 1fr); 
    grid-template-rows: 1fr;
`;

export const InnerContainerBottomContentLang = styled.div<{ mobile: boolean }>`
    width:100%;
    max-width:1100px;
    margin:0 auto;
    color: #FFFFFF;
    display: grid;
    grid-gap: 0rem;
    grid-template-columns: repeat(1, 1fr); 
`;
export const BottomPaneDiv = styled.div<{ mobile: boolean }>`
    width:${props => props.mobile ? "calc(100% + 50px)" : "100%"};
    max-width:1100px;
    display: inline-block;
    border:1px solid #FFF;
    position: relative;
    padding:${props => appendPx(props.mobile ? 20 : 50)};
    background: rgba(0,0,0,0.7);
    text-align:left;
    margin: auto;
    margin-left:${props => appendPx(props.mobile ? -25 : 0)};
    font-size:1.1rem;
    line-height:1.6rem;
    color:#FFF;
    min-height: ${props => props.mobile ? 0 : "100%"};
    transition: all 0.2s ease-in-out;
    -webkit-box-shadow: 0 8px 8px -6px #000  !important;
    box-shadow: 0 8px 8px -6px #000  !important;
`;

export const BottomPane = styled.a<{ mobile: boolean }>`
    width:${props => props.mobile ? "calc(100% + 50px)" : "100%"};
    max-width:1100px;
    display: inline-block;
    border:1px solid #FFF;
    padding:${props => appendPx(props.mobile ? 20 : 50)};
    background: rgba(0,0,0,0.7);
    text-align:left;
    margin: auto;
    margin-left:${props => appendPx(props.mobile ? -25 : 0)};
    font-size:1.1rem;
    line-height:1.6rem;
    color:#FFF;
    min-height: ${props => props.mobile ? 0 : "100%"};
    transition: all 0.2s ease-in-out;
    -webkit-box-shadow: 0 8px 8px -6px #000  !important;
    box-shadow: 0 8px 8px -6px #000  !important;
    cursor:pointer;
    &:hover{
        background: rgba(0,0,0,0.9);
        border:1px solid #950014;
        color: #FFF;
        i.icon{
            color: #950014;
        }
    }
`;


export const BottomPaneLanguage = styled.div<{ mobile: boolean }>`
    width:${props => props.mobile ? "calc(100% + 50px)" : "100%"};
    max-width:1100px;
    display: inline-block;
    border:1px solid #FFF;
    padding:${props => appendPx(props.mobile ? 20 : 20)};
    background: rgba(0,0,0,0.7);
    text-align: center;
    margin: 1rem auto;
    margin-left: ${props => appendPx(props.mobile ? -25 : 0)};
    font-size:1.1rem;
    line-height:1.6rem;
    color:#FFF;
    transition: all 0.2s ease-in-out;
    -webkit-box-shadow: 0 8px 8px -6px #000  !important;
    box-shadow: 0 8px 8px -6px #000  !important;
    cursor:pointer;
    &:hover{
        background: rgba(0,0,0,0.9);
        border:1px solid #950014;
        i.icon{
            color: #950014;
        }
    }
`;
export const HeadlineBottomPane = styled.div<{ mobile: boolean }>`
    font-size:${props => props.mobile ? "2rem" : "3rem"};
    line-height:${props => props.mobile ? "2rem" : "3rem"};
    font-weight:bolder;
`;
export const ContentBottom = styled.div`
    padding-top:30px;
    font-size:1.1rem;
    line-height:1.6rem;
`;

export const Buttons = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: grid;
    display: -ms-grid;
    grid-template-columns: 1fr 1fr;
    -ms-grid-columns: 1fr 1fr;
`;

export const Btn = styled.a`
    width: calc(100% - 20px);
    border:1px solid #FFF;
    position: relative;
    padding: 20px;
    background: rgba(0,0,0,0.7);
    text-align:center;
    margin: 10px;
    font-size:1.1rem;
    line-height:1.6rem;
    color:#FFF;
    transition: all 0.2s ease-in-out;
    -webkit-box-shadow: 0 8px 8px -6px #000  !important;
    box-shadow: 0 8px 8px -6px #000  !important;
    &:hover{
        background: rgba(0,0,0,0.9);
        border:1px solid #950014;
        color: #FFF;
        i.icon{
            color: #950014;
        }
    }
`;


export const SelLang = styled.span`
    color:rgba(255,255,255,0.5);
    font-size:1.15rem;    
`;
export const LangSelect = styled.div`
    font-size:1.1rem;
    text-align:left;
    margin:auto;
    width:150px;
    line-height:2.5rem;
    cursor:pointer;
    transition: all 0.2s ease-in-out;
    &:hover{
        color: #950014;
        transform: translateX(10px);
    }
`;

interface TextBlock {
    headline: string;
    text: string;
    icon?: SemanticICONS;
    linkName?: string;
}

interface Texts {
    lngName: string;
    flag: string;
    changeLang: string;
    legal: TextBlock;
    top: TextBlock;
    bottom: TextBlock[];
}

const templateLinks = [
    "http://pcm.smart-mobility.info/token/{{token}}?locale={{lng}}",
    "http://pcm-cl33.smart-mobility.info/token/{{token}}?locale={{lng}}",
    "#/tube/0a550f3e-d5da-404b-ab21-9974b0dc3a59/only"
]

const lngMap: { [lng: string]: string } = {
    "de-DE": "de_DE",
    "en-EN": "en_GB",
    "en-US": "en_US",
    "es-ES": "es_ES",
    "fr-FR": "fr_FR",
    "it-IT": "it_IT",
    "ja-JP": "ja_JP",
    "ko-KR": "ko_KR",
    "nl-NL": "nl_NL",
    "pt-BR": "pt_BR",
    "ru-RU": "ru_RU",
    "zh-CN": "zh_CN",
    "zh-TW": "zh_TW"
}


class PluginLandingPage extends React.Component<PluginLandingPageProps, PluginLandingPageState> {

    componentDidMount() {
        this.props.dispatch(ActionCreators.SetHomeLink(config.routes.plugin.main));
    }

    constructor(props: PluginLandingPageProps) {
        super(props);

        this.state = {
            legalOpen: false,
            languageOpen: false,
            selectedLang: this.props.state.userInfo?.userSettings.language ?? ImgI18N.getInstance().getBrowserLanguage().value
        }
    }
    public render() {
        //        console.log("size => ", this.props.width);
        const mobile = belowBreakpointOrEqual(this.props, "tablet");
        const lng = this.state.selectedLang;
        let t = texts[lng] ?? texts["en-GB"];
        const links = _.map(templateLinks, l => {
            return l.replace("{{token}}", store.getState().client.token)
                .replace("{{groupid}}", "0a550f3e-d5da-404b-ab21-9974b0dc3a59")
                .replace("{{lng}}", lngMap[lng] ?? "en_GB");
        })
        return (
            <OuterContainer>
                <InnerContainerTop mobile={mobile}>
                    <InnerContainerTopContent>
                        <Legal onClick={this.showModalLegal}>{t.legal.linkName}</Legal>
                        <HeadlineTop mobile={mobile}>{t.top.headline}</HeadlineTop>
                        <ContentTop mobile={mobile}>
                            {splitTextToPara(t.top.text)}
                        </ContentTop>
                    </InnerContainerTopContent>
                </InnerContainerTop>
                <InnerContainerBottom mobile={mobile}>
                    <InnerContainerBottomContent mobile={mobile}>
                        <BottomPaneDiv mobile={mobile} style={{ paddingBottom: 85 }}>
                            <HeadlineBottomPane mobile={mobile}>
                                {t.bottom[0].headline}
                                <Icon name={t.bottom[0].icon} style={{ float: "right" }} />
                            </HeadlineBottomPane>
                            <ContentBottom>
                                {splitText(t.bottom[0].text)}
                            </ContentBottom>
                            <Buttons>
                                <Btn href={links[0]}>
                                    Cluster 32
                                </Btn>
                                <Btn href={links[1]}>
                                    Cluster 33
                                </Btn>
                            </Buttons>

                        </BottomPaneDiv>
                        <BottomPane href={links[2]} mobile={mobile}>
                            <HeadlineBottomPane mobile={mobile}>
                                {t.bottom[1].headline}
                                <Icon name={t.bottom[1].icon} style={{ float: "right" }} />
                            </HeadlineBottomPane>
                            <ContentBottom>
                                {splitText(t.bottom[1].text)}
                            </ContentBottom>
                        </BottomPane>
                        {/* {_.map(t.bottom, (tt, i) => (
                            <BottomPane key={i} href={links[i]} mobile={mobile}>
                                <HeadlineBottomPane mobile={mobile}>
                                    {tt.headline}
                                    <Icon name={tt.icon} style={{ float: "right" }} />
                                </HeadlineBottomPane>
                                <ContentBottom>
                                    {splitText(tt.text)}
                                </ContentBottom>
                            </BottomPane>
                        ))} */}
                    </InnerContainerBottomContent>
                    <InnerContainerBottomContentLang mobile={mobile}>
                        <BottomPaneLanguage mobile={mobile}>
                            {!this.state.languageOpen &&
                                <div onClick={this.showLangSelect}>
                                    <Flag name={t.flag as FlagNameValues} /> {t.lngName}
                                    <Icon name="chevron down" style={{ float: "right", marginRight: 9 }} />
                                </div>
                            }
                            {this.state.languageOpen &&
                                <div>
                                    <Icon name="chevron up" style={{ float: "right", marginRight: 9 }} onClick={this.closeLangSelect} />
                                    {_.map(texts, (tt, j) => (
                                        <LangSelect key={j} onClick={this.langSelect(j)}>
                                            <Flag name={tt.flag as FlagNameValues} />
                                            {tt.lngName === t.lngName ? <SelLang>{tt.lngName}</SelLang> : tt.lngName}
                                        </LangSelect>
                                    ))}
                                </div>
                            }
                        </BottomPaneLanguage>
                    </InnerContainerBottomContentLang>
                </InnerContainerBottom>
                <Modal open={this.state.legalOpen} basic onClose={this.closeModalLegal}>
                    <Modal.Header style={{ background: "#222527" }}>
                        {t.legal.headline}
                        <Button icon="times" color="red" floated="right" onClick={this.closeModalLegal} style={{ marginTop: -5 }} />
                    </Modal.Header>
                    <Modal.Content scrolling style={{ background: "#313639", fontSize: "1.1rem", lineHeight: "1.6rem" }}>
                        {splitTextToPara(t.legal.text)}
                    </Modal.Content>
                </Modal>
            </OuterContainer>
        );
    }
    private showModalLegal = () => this.setState({ legalOpen: true });
    private closeModalLegal = () => this.setState({ legalOpen: false });
    private showLangSelect = () => this.setState({ languageOpen: true });
    private closeLangSelect = () => this.setState({ languageOpen: false });
    private langSelect = (lang: string) => () => this.setState(
        { languageOpen: false, selectedLang: lang },
        () => this.props.dispatch(ActionCreators.globalState.ChangeLanguage(lang))
    );
}

export const texts: { [lng: string]: Texts } = {
    "de": {
        lngName: "Deutsch",
        flag: "de-DE",
        changeLang: "Change language",
        legal: {
            linkName: "Rechtliches",
            headline: "Nur zur internen Verwendung!",
            text: "Diese Anwendung dient ausschließlich der internen Verwendung innerhalb der Porsche Handelsorganisation. Der primäre Zweck ist die Qualifizierung der Porsche Handelsorganisation.\n" +
                "Alle Texte, Bilder und sonstigen Informationen in dieser Anwendung unterliegen dem Urheberrecht der Dr. Ing. h.c. F. Porsche AG.\n" +
                "Bei der Anwendung handelt es sich um eine Simulation. Daher können Texte, Bilder und Informationen vom eigentlichen Produkt abweichen. Die Anwendung enthält keine länderspezifischen Ausprägungen oder spezifische Konfigurationen. Änderungen am eigentlichen Produkt werden ggf. nicht oder nur mit Verzögerung in der Simulation abgebildet.\n" +
                "Jede Vervielfältigung, Wiedergabe oder sonstige Nutzung ohne vorherige schriftliche Zustimmung der Dr. Ing. h.c. F. Porsche AG ist unzulässig.\n" +
                "Hinweis:\n" +
                "Alle Informationen entsprechen dem Kenntnisstand vom November 2019.\n" +
                "© Dr. Ing. h.c. F. Porsche Aktiengesellschaft\n" +
                "Abteilung VCQ / Vertriebsqualifizierung\n" +
                "Porscheplatz 1\n" +
                "D-70435 Stuttgart\n" +
                "www.porsche.com\n" +
                "Änderungen und Irrtümer vorbehalten.\n"
        },
        top: {
            headline: "Willkommen zum PCM und Porsche Connect Tutorial!",
            text: "Das PCM und die Porsche Connect Dienste im Porsche Taycan sind für die Nutzung des Fahrzeugs von großer Bedeutung. Mit dieser Anwendung kannst Du Dich über die wesentlichen Themen rund um das PCM sowie die dazugehörigen Porsche Connect Dienste im Taycan informieren. Neben dem freien Ausprobieren innerhalb des Bereiches „Discover now!“ unterstützen wir Dich bei konkreten Fragestellungen mit detaillierten Anleitungen unter „Learn more!“.\n\n" +
                "Viel Spaß bei der Nutzung des PCM und Porsche Connect Tutorials!\n" +
                "Euer Porsche Training Team"
        },
        bottom: [
            {
                icon: "hand point up outline",
                headline: "Discover now!",
                text: "Willst Du Dich mit dem neuen PCM vertraut machen oder bequem bestimmte Funktionalitäten nochmal nachschauen?\n\n" +
                    "In diesem Bereich kannst Du Dich frei durch das neue System klicken. Schau Dich um und mach Dich an Deinem PC oder auf Deinem Tablet mit der Struktur und den Funktionen der neuen Oberfläche vertraut."
            },
            {
                icon: "play circle",
                headline: "Learn more!",
                text: "Hast Du konkrete Fragestellungen oder möchtest Dich detailliert mit dem PCM sowie den Porsche Connect Diensten beschäftigen? Sortiert nach verschiedenen Kategorien werden Fragestellungen gelöst und erklärt. Dabei lernst Du Schritt für Schritt das PCM und die Porsche Connect Dienste direkt im System kennen. Eine perfekte Vorbereitung für deine Kundengespräche."
            },
        ]
    },
    "en-GB": {
        lngName: "English GB",
        flag: "gb",
        changeLang: "Change language",
        legal: {
            linkName: "Legal",
            headline: "For internal use only!",
            text: "This application is solely for internal use within the Porsche sales organization. The primary purpose of the application is to train the Porsche sales organization.\n" +
                "All text, images and other information contained in this application are subject to the copyright of Dr. Ing. h.c. F. Porsche AG. \n" +
                "The application is a simulation. For this reason, texts, images and information may differ from the actual product. The application does not contain any country-specific versions or specific configurations. Changes to the actual product may not be reflected in the simulation at all or only after a delay.\n" +
                "Any reproduction, duplication or other use is prohibited without the prior written consent of Dr. Ing. h.c. F. Porsche AG.\n" +
                "Note:\n" +
                "All information contained here reflects the state of knowledge as of November 2019.\n" +
                "© Dr. Ing. h.c. F. Porsche Aktiengesellschaft\n" +
                "Department VCQ/Sales Qualification\n" +
                "Porscheplatz 1\n" +
                "D-70435 Stuttgart, Germany\n" +
                "www.porsche.com\n" +
                "ubject to change. Errors excepted.\n"
        },
        top: {
            headline: "Welcome to the PCM and Porsche Connect Tutorial!",
            text: "The PCM and the Porsche Connect services in the Porsche Taycan are very important for using the car. You can use this application to learn more about key topics regarding the PCM and the associated Porsche Connect services in the Taycan. You can try out the system in the “Discover now!” section, and we provide support in the form of specific questions with detailed instructions in the “Learn more!” section." +
                "Have fun using the PCM and Porsche Connect tutorials!\nYour Porsche training team"
        },
        bottom: [
            {
                icon: "hand point up outline",
                headline: "Discover now!",
                text: "Would you like to familiarize yourself with the new PCM or simply check out certain functions again? You can click through the new system in this section. Take a look around and familiarize yourself with the structure and functions on the new interface using your PC or tablet."
            },
            {
                icon: "play circle",
                headline: "Learn more!",
                text: "Do you have specific questions or would you like to understand the PCM and the Porsche Connect services in more detail? This section contains various questions and answers, sorted into different categories. You can learn about the PCM and the Porsche Connect services step-by-step, directly in the system, offering you the ideal way to prepare for discussions with your customers."
            },
        ]
    },
    "en-US": {
        lngName: "English US",
        flag: "us",
        changeLang: "Change language",
        legal: {
            linkName: "Legal",
            headline: "For internal use only!",
            text: "This application is solely for internal use within the Porsche sales organization. The primary purpose of the application is to train the Porsche sales organization.\n" +
                "All text, images and other information contained in this application are subject to the copyright of Dr. Ing. h.c. F. Porsche AG. \n" +
                "The application is a simulation. For this reason, texts, images and information may differ from the actual product. The application does not contain any country-specific versions or specific configurations. Changes to the actual product may not be reflected in the simulation at all or only after a delay.\n" +
                "Any reproduction, duplication or other use is prohibited without the prior written consent of Dr. Ing. h.c. F. Porsche AG.\n" +
                "Note:\n" +
                "All information contained here reflects the state of knowledge as of November 2019.\n" +
                "© Dr. Ing. h.c. F. Porsche Aktiengesellschaft\n" +
                "Department VCQ/Sales Qualification\n" +
                "Porscheplatz 1\n" +
                "D-70435 Stuttgart, Germany\n" +
                "www.porsche.com\n" +
                "ubject to change. Errors excepted.\n"
        },
        top: {
            headline: "Welcome to the PCM and Porsche Connect Tutorial!",
            text: "The PCM and the Porsche Connect services in the Porsche Taycan are very important for using the car. You can use this application to learn more about key topics regarding the PCM and the associated Porsche Connect services in the Taycan. You can try out the system in the “Discover now!” section, and we provide support in the form of specific questions with detailed instructions in the “Learn more!” section."
        },
        bottom: [
            {
                icon: "hand point up outline",
                headline: "Discover now!",
                text: "Would you like to familiarize yourself with the new PCM or simply check out certain functions again? You can click through the new system in this section. Take a look around and familiarize yourself with the structure and functions on the new interface using your PC or tablet."
            },
            {
                icon: "play circle",
                headline: "Learn more!",
                text: "Do you have specific questions or would you like to understand the PCM and the Porsche Connect services in more detail? This section contains various questions and answers, sorted into different categories. You can learn about the PCM and the Porsche Connect services step-by-step, directly in the system, offering you the ideal way to prepare for discussions with your customers."
            },
        ]
    },
    "es-ES": {
        lngName: "Español",
        flag: "es",
        changeLang: "Change language",
        legal: {
            linkName: "Información legal",
            headline: "Solo para uso interno.",
            text: "Esta aplicación está prevista exclusivamente para su uso interno dentro de la organización comercial de Porsche. Su objetivo principal es la formación de la organización comercial de Porsche. \n" +
                "Todos los textos, las imágenes y cualquier otra información incluidos en esta aplicación están sujetos al derecho de propiedad intelectual de Dr. Ing. h.c. F. Porsche AG.  \n" +
                "Esta aplicación es una simulación. Por lo tanto, los textos, las imágenes y la información mostrada sobre el producto pueden diferir de la versión real del producto. La aplicación no incluye características ni configuraciones específicas de cada país. Si se produce cualquier cambio en el producto, no se reflejará o solo se reflejará con retraso en la simulación en caso necesario.\n" +
                "Queda prohibida su copia, reproducción o cualquier otro uso sin el consentimiento previo por escrito de Dr. Ing. h.c. F. Porsche AG.\n" +
                "Nota:\n" +
                "Todos los datos facilitados corresponden al nivel de información disponible en noviembre de 2019.\n" +
                "© Dr. Ing. h.c. F. Porsche Aktiengesellschaft\n" +
                "Departamento VCQ/Formación Red Comercial\n" +
                "Porscheplatz 1\n" +
                "D-70435 Stuttgart, Germany\n" +
                "www.porsche.com\n" +
                "Reservado el derecho a modificaciones y errores.\n"
        },
        top: {
            headline: "Te damos la bienvenida al tutorial de PCM y Porsche Connect.",
            text: "El PCM y los servicios Porsche Connect del Porsche Taycan son esenciales para el uso del vehículo. Con esta aplicación puedes informarte sobre los principales temas relacionados con el PCM y los servicios Porsche Connect correspondientes del Taycan. Puedes disfrutar de una prueba haciendo clic en «Descúbrelos» y también obtener ayuda sobre cuestiones concretas con instrucciones detalladas en el apartado «Más información»." +
                "Esperamos que disfrutes con los tutoriales de PCM y Porsche Connect. \nTu equipo de formación de Porsche"
        },
        bottom: [
            {
                icon: "hand point up outline",
                headline: "Descúbrelos",
                text: "Si quieres familiarizarte con el nuevo PCM o volver a probar algunas funcionalidades concretas de forma sencilla, haz clic en este área para navegar libremente por el nuevo sistema. Solo necesitas tu ordenador o tableta para familiarizarte con la estructura y las funciones de la nueva interfaz."
            },
            {
                icon: "play circle",
                headline: "Más información",
                text: "Si tienes dudas concretas que quieres aclarar o te gustaría obtener más información sobre los servicios Porsche Connect y el PCM, en este apartado podrás encontrar todas las cuestiones resueltas y explicadas clasificadas por categorías. Además, podrás familiarizarte paso a paso con el PCM y los servicios Porsche Connect directamente en el sistema. El entrenamiento perfecto para tus conversaciones con los Clientes."
            },
        ]
    },
    "fr-FR": {
        lngName: "Français",
        flag: "fr",
        changeLang: "Change language",
        legal: {
            linkName: "Informations juridiques",
            headline: "Usage interne uniquement !",
            text: "Cette application est exclusivement destinée à un usage interne au sein du réseau de distribution Porsche. Elle est principalement conçue pour la qualification du réseau de distribution Porsche. \n" +
                "Le texte, tout comme les images et autres informations contenues dans cette application, sont soumis aux droits d’auteur de la société Dr. Ing. h.c. F. Porsche AG.  \n" +
                "L’application est un outil de simulation. Par conséquent, les textes, les images et les informations contenus peuvent être différents du produit en question. L’application ne contient ni caractéristiques propres aux différents pays ni configurations spécifiques. Les modifications apportées au produit réel peuvent ne pas être illustrées dans la simulation, ou seulement avec un certain retard.\n" +
                "Toute duplication, reproduction ou autre utilisation est strictement interdite sans autorisation écrite préalable de la société Dr. Ing. h.c. F. Porsche AG.\n" +
                "Remarque :\n" +
                "Toutes les informations correspondent aux données disponibles en novembre 2019.\n" +
                "© Dr. Ing. h.c. F. Porsche AG\n" +
                "Département VCQ / Qualification de distribution\n" +
                "Porscheplatz 1\n" +
                "D-70435 Stuttgart, Germany\n" +
                "www.porsche.com\n" +
                "Sous réserve de modifications et d’erreurs.\n"
        },
        top: {
            headline: "Bienvenue dans le tutoriel PCM et Porsche Connect !",
            text: "Le PCM et les services Porsche Connect du Porsche Taycan sont d’une importance capitale pour l’utilisation du véhicule. Grâce à cette application, vous pouvez vous informer sur les thèmes en lien avec le PCM du modèle Taycan ainsi que les services Porsche Connect y afférant. Outre l’essai gratuit disponible dans la rubrique « Discover now! », vous trouverez des réponses exhaustives à vos questions dans les guides disponibles dans la rubrique « Learn more! »." +
                "Nous vous souhaitons beaucoup de plaisir avec les tutoriels PCM et Porsche Connect !\nL’équipe de formation Porsche"
        },
        bottom: [
            {
                icon: "hand point up outline",
                headline: "Discover now!",
                text: "Vous souhaitez vous familiariser avec le nouveau PCM ou alors simplement réviser quelques fonctionnalités ? Cette rubrique vous permet de découvrir le nouveau système. À l’aide de votre PC ou de votre tablette, découvrez la structure et les fonctions de la nouvelle interface et familiarisez-vous avec elles. "
            },
            {
                icon: "play circle",
                headline: "Learn more!",
                text: "Vous avez des questions concrètes ou souhaitez maîtriser le PCM et les services Porsche Connect dans les détails ? Vous trouverez ici des réponses et des explications à vos questions classées par catégories. Cela vous permet de découvrir pas à pas le PCM et les services Porsche Connect, et ce directement dans le système. Vous bénéficiez ainsi d’une préparation optimale en vue de vos entretiens clients."
            },
        ]
    },
    "it-IT": {
        lngName: "Italiano",
        flag: "it",
        changeLang: "Change language",
        legal: {
            linkName: "Informazioni legali",
            headline: "Solo per uso interno!",
            text: "La presente applicazione è a esclusivo uso interno dell’organizzazione commerciale Porsche. L’obiettivo principale è la formazione dei dipendenti dell’organizzazione commerciale Porsche.\n" +
                "Tutti i testi, le immagini e le altre informazioni contenute in questa applicazione sono soggetti al copyright di Dr. Ing. h.c. F. Porsche AG.\n" +
                "L’applicazione rappresenta una simulazione. Pertanto, i testi, le immagini e le informazioni potrebbero differire dal prodotto reale. L’applicazione non contiene versioni specifiche per i diversi Paesi o configurazioni specifiche. Le modifiche al prodotto reale potrebbero non essere apportate nella simulazione o potrebbero essere apportate con ritardo.\n" +
                "È vietata la duplicazione, riproduzione o altro utilizzo del presente documento senza previa autorizzazione scritta di Dr. Ing. h.c. F. Porsche AG.\n" +
                "Nota:\n" +
                "Tutte le informazioni sono aggiornate allo stato dell’arte del mese di novembre 2019.\n" +
                "© Dr. Ing. h.c. F. Porsche Aktiengesellschaft\n" +
                "Reparto VCQ/Sales Network Qualification\n" +
                "Porscheplatz 1\n" +
                "D-70435 Stoccarda (Germania)\n" +
                "www.porsche.com\n" +
                "Con riserva di modifiche ed errori.\n"
        },
        top: {
            headline: "Benvenuto nel tutorial sul PCM e Porsche Connect!",
            text: "Il PCM e i servizi Porsche Connect presenti nella Porsche Taycan rivestono un ruolo di primaria importanza per l’utilizzo della vettura. Tramite questa applicazione può ottenere informazioni sui temi principali relativi al PCM e ai servizi Porsche Connect associati di cui è dotata la Taycan. Oltre alla prova gratuita all’interno dell’area “Discover now!”, offriamo supporto con quesiti concreti e istruzioni dettagliate in “Learn more!”." +
                "Le auguriamo buon divertimento con i tutorial sull’utilizzo del PCM e di Porsche Connect! \nIl Porsche Training Team"
        },
        bottom: [
            {
                icon: "hand point up outline",
                headline: "Discover now!",
                text: "Desidera acquisire dimestichezza con il nuovo PCM o rivedere comodamente alcune funzionalità? In questa sezione può navigare liberamente nel nuovo sistema. Può esplorarlo e familiarizzare con la struttura e le funzionalità della nuova interfaccia sul suo PC o tablet."
            },
            {
                icon: "play circle",
                headline: "Learn more!",
                text: "Ha domande specifiche o desidera esaminare nel dettaglio il PCM e i servizi Porsche Connect? Le domande vengono affrontate e ordinate in categorie differenti. In questo modo, imparerà a conoscere il PCM e i servizi Porsche Connect passo dopo passo direttamente nel sistema. Una preparazione perfetta per i colloqui con i clienti. "
            },
        ]
    },
    "ja-JP": {
        lngName: "ドイツ語",
        flag: "jp",
        changeLang: "Change language",
        legal: {
            linkName: "法律上の情報",
            headline: "社外秘！",
            text: "このアプリケーションは、ポルシェのセールス部門内部でのみ使用することを目的に作成されたものです。その目的は、ポルシェのセールス部門のトレーニングです。 \n" +
                "本アプリケーション内のすべてのテキストおよびその他の情報は、Dr. Ing. h.c. F. Porsche AGが著作権を有しています。 \n" +
                "本アプリケーションはシミュレーションです。従って、テキスト、画像、情報は、実際の製品とは異なることがあります。本アプリケーションには、国/地域固有の特徴または特殊な構成は含まれていません。実際の製品での変更はシミュレーションには表示されないか、必要に応じて遅れて表示されます。\n" +
                "いかなる場合も、Dr. Ing. h.c. F. Porsche AGの文書による事前の許可なく、複製、再販またはその他の用途に利用することは許可されません。\n" +
                "注:\n" +
                "記載内容はすべて2019年11月現在のものです。\n" +
                "© Dr. Ing. h.c. F. Porsche AG\n" +
                "Abteilung VCQ / Vertriebsqualifizierung\n" +
                "Porscheplatz 1\n" +
                "D-70435 Stuttgart\n" +
                "www.porsche.com\n" +
                "内容は変更される場合があります。\n"
        },
        top: {
            headline: "PCMおよびポルシェ コネクトチュートリアルへようこそ！",
            text: "ポルシェ タイカンのPCMおよびポルシェ コネクトサービスは、車両の使用に大きな影響を与えます。このアプリケーションを利用して、タイカンのPCMならびにそれに属するポルシェ コネクトサービスに関する重要なテーマについて情報を受け取ることができます。「Discover now!」のエリア内で無料体験できる以外にも、「Learn more!」の詳細な説明によって具体的な質問をサポートします。" +
                "PCMおよびポルシェ コネクトチュートリアルをどうぞお楽しみください！ \nポルシェ トレーニングチーム"
        },
        bottom: [
            {
                icon: "hand point up outline",
                headline: "Discover now!",
                text: "新しいPCMを習得したいですか？それとも特定の機能を簡単に再確認したいですか？このエリアでは、この新しいシステムを無料で使用することができます。新しいシステムがどんなものか、まずご覧いただき、新しくなったユーザーインターフェイスの構成と機能をPCやタブレットで習得してください。"
            },
            {
                icon: "play circle",
                headline: "Learn more!",
                text: "具体的にご不明な点がありますか？それともPCMとポルシェ コネクトサービス全般について詳しくなりたいのですか？質問はさまざまなカテゴリー別に解答され、解説されます。それによって、システム内で直接PCMおよびポルシェ コネクトサービスを徐々に理解していきます。セールストークに万全の準備で望んでください。"
            },
        ]
    },
    "ko-KR": {
        lngName: "독일어",
        flag: "kr",
        changeLang: "Change language",
        legal: {
            linkName: "적법한",
            headline: "내부용!",
            text: "본 응용 프로그램은 포르쉐 딜러 임직원들이 내부적으로 사용하기 위한 것입니다. 주요 목적은 포르쉐 딜러 조직을 교육하는 것입니다.\n" +
                "본 응용 프로그램에 입력된 모든 글, 사진 및 기타 정보에 대한 저작권은 Dr. Ing. h.c. F. Porsche AG 측에 있습니다.\n" +
                "본 응용 프로그램은 시뮬레이션 콘텐츠입니다. 따라서 글, 이미지 및 정보가 실제 제품과 다를 수 있습니다. 국가별 특징이나 특정 구성은 응용 프로그램에 적용되어 있지 않습니다. 실제 제품에 대한 변경 사항은 경우에 따라 시뮬레이션에 반영되지 않거나 차후에 반영됩니다.\n" +
                "모든 복제, 전재 또는 기타 사용은 Dr. Ing. h.c. F. Porsche AG의 서면 허가 없이 이루어져서는 안 됩니다.\n" +
                "참고:\n" +
                "모든 정보는 2019년 11월의 데이터를 기준으로 합니다.\n" +
                "© Dr. Ing. h.c. F. Porsche Aktiengesellschaft\n" +
                "VCQ팀/판매인증\n" +
                "Porscheplatz 1\n" +
                "D-70435 Stuttgart, Germany\n" +
                "www.porsche.com\n" +
                "변경 및 오류가 있을 수 있습니다.\n"
        },
        top: {
            headline: "PCM 및 포르쉐 커넥트 튜토리얼에 오신 것을 환영합니다!",
            text: "포르쉐 타이칸에 제공되는 PCM과 포르쉐 커넥트 서비스는 차량 이용 시 매우 중요한 역할을 합니다. 이 튜토리얼에서 타이칸의 PCM 및 관련 포르쉐 커넥트 서비스 전반에 대한 중요한 주제를 알아볼 수 있습니다. 자유롭게 체험할 수 있는 “Discover now!” 영역과 더불어 구체적인 질문 및 상세한 지침이 포함된 “Learn more!” 영역이 준비되어 있습니다." +
                "PCM 및 포르쉐 커넥트 튜토리얼을 이용하며 즐거운 시간 보내시기 바랍니다! \n포르쉐 트레이닝 팀"
        },
        bottom: [
            {
                icon: "hand point up outline",
                headline: "Discover now!",
                text: "새로운 PCM에 대해 알아보시겠습니까, 특정 기능을 다시 한번 살펴보시겠습니까? 이 영역에서는 새로운 시스템을 자유롭게 클릭할 수 있습니다. 시스템을 둘러본 다음 PC 또는 태블릿에서 새 인터페이스의 구조와 기능에 대해 알아보십시오. "
            },
            {
                icon: "play circle",
                headline: "Learn more!",
                text: "구체적인 질문이 있거나 PCM 및 포르쉐 커넥트 서비스에 대해 자세히 알아보고 싶으십니까? 다양한 카테고리별 질문을 풀고 나면 설명이 나타납니다. 이로써 시스템에서 곧바로 PCM과 포르쉐 커넥트 서비스를 단계적으로 학습하게 됩니다. 이를 통해 고객 상담을 완벽하게 준비할 수 있습니다."
            },
        ]
    },
    "nl-NL": {
        lngName: "Nederlands",
        flag: "nl",
        changeLang: "Change language",
        legal: {
            linkName: "Juridisch",
            headline: "Alleen voor intern gebruik!",
            text: "Deze toepassing is uitsluitend bedoeld voor intern gebruik binnen de Porsche handelsorganisatie. Het primaire doel is de kwalificatie van de Porsche handelsorganisatie.\n" +
                "Alle teksten, foto’s en overige gegevens in deze toepassing zijn auteursrechtelijk eigendom van Dr. Ing. h.c. F. Porsche AG.\n" +
                "Bij deze toepassing gaat het om een simulatie. Teksten, afbeeldingen en gegevens kunnen daarom afwijken van het eigenlijke product. De toepassing bevat geen landspecifieke uitrustingen of specifieke configuraties. Wijzigingen aan het eigenlijke product worden mogelijk niet of met vertraging in de simulatie weergegeven.\n" +
                "Elke vorm van vermenigvuldiging, vertoning of overig gebruik van dit materiaal is niet toegestaan zonder voorafgaande, schriftelijke toestemming van Dr. Ing. h.c. F. Porsche AG.\n" +
                "Opmerking:\n" +
                "Alle informatie is gebaseerd op gegevens zoals die bekend waren in november 2019.\n" +
                "© Dr. Ing. h.c. F. Porsche Aktiengesellschaft\n" +
                "Afdeling VCQ / Vertriebsqualifizierung\n" +
                "Porscheplatz 1\n" +
                "D-70435 Stuttgart\n" +
                "www.porsche.com\n" +
                "Wijzigingen en fouten voorbehouden.\n"
        },
        top: {
            headline: "Welkom bij het PCM en de Porsche Connect Tutorial!",
            text: "Het PCM en de Porsche Connect diensten in de Porsche Taycan zijn van groot belang voor het gebruik van de auto. Met deze toepassing krijg je informatie over essentiële onderwerpen rondom het PCM en over de bijbehorende Porsche Connect diensten in de Taycan. Naast de gratis proefversie in het gedeelte ‘Discover now!’ beantwoorden wij je concrete vragen met gedetailleerde instructies onder ‘Learn more!’." +
                "Veel plezier bij het gebruik van het PCM en de Porsche Connect Tutorials!\nHet Porsche Training Team"
        },
        bottom: [
            {
                icon: "hand point up outline",
                headline: "Discover now!",
                text: "Wil het nieuwe PCM leren kennen of wil je gemakkelijk bepaalde functionaliteiten nog eens bekijken? In dit gedeelte kun je vrij door het nieuwe systeem navigeren. Kijk rond en raak vertrouwd met de structuur en functies van de nieuwe interface op je pc of tablet. "
            },
            {
                icon: "play circle",
                headline: "Learn more!",
                text: "Heb je specifieke vragen of wil je dieper ingaan op het PCM en de Porsche Connect diensten? Gesorteerd op verschillende categorieën worden vragen opgelost en toegelicht. Daarbij leer je stap voor stap het PCM en de Porsche Connect diensten meteen in het systeem kennen. Een perfecte voorbereiding op een gesprek met een klant. "
            },
        ]
    },
    "pt-BR": {
        lngName: "Português",
        flag: "pt",
        changeLang: "Change language",
        legal: {
            linkName: "Legal",
            headline: "Somente para uso interno!",
            text: "Este aplicativo é exclusivamente para uso interno dentro da organização comercial Porsche. O objetivo principal é a qualificação da organização comercial Porsche.\n" +
                "Todos os textos, fotos e outras informações neste aplicativo estão sujeitos aos direitos autorais da Dr. Ing. h.c. F. Porsche AG.\n" +
                "O aplicativo é uma simulação. Assim sendo, textos, imagens e informações podem diferir do produto real. O aplicativo não contém nenhuma característica específica do país ou configuração específica. Alterações no produto real podem não ser refletidas na simulação ou apenas ser refletidas com algum atraso.\n" +
                "Qualquer cópia, reprodução ou outro tipo de utilização sem o prévio consentimento por escrito da Dr. Ing. h.c. F. Porsche AG é proibida.\n" +
                "Nota:\n" +
                "Todas as informações correspondem ao estado atual de conhecimento em novembro de 2019.\n" +
                "© Dr. Ing. h.c. F. Porsche Aktiengesellschaft\n" +
                "Departamento VCQ/Qualificação da rede de vendas\n" +
                "Porscheplatz 1\n" +
                "D-70435 Stuttgart\n" +
                "www.porsche.com\n" +
                "Sujeito a alterações e incorreções.\n"
        },
        top: {
            headline: "Bem-vindo ao Tutorial PCM e Porsche Connect!",
            text: "O PCM e os serviços Porsche Connect no Porsche Taycan são muito importantes para o uso do veículo. Com este aplicativo, você pode se informar sobre os principais temas do PCM, bem como os serviços Porsche Connect associados no Taycan. Além da avaliação gratuita na área 'Descubra agora!', oferecemos suporte a perguntas concretas com instruções detalhadas em 'Aprenda mais!'." +
                "Divirta-se usando os tutoriais PCM e Porsche Connect!\nSua Porsche Training Team"
        },
        bottom: [
            {
                icon: "hand point up outline",
                headline: "Descubra agora!",
                text: "Deseja se familiarizar com o novo PCM ou procurar novamente determinadas funcionalidades? Nesta área, você pode clicar livremente sobre o novo sistema. Dê uma olhada e familiarize-se com a estrutura e as funções da nova interface no seu PC ou tablet."
            },
            {
                icon: "play circle",
                headline: "Aprenda mais!",
                text: "Você tem perguntas específicas ou deseja saber mais detalhes sobre o PCM e os serviços Porsche Connect? Classificadas por diferentes categorias, as perguntas são respondidas e explicadas. Você ficará a conhecer passo a passo o PCM e os serviços Porsche Connect diretamente no sistema. Uma preparação perfeita para suas conversas com clientes."
            },
        ]
    },
    "ru-RU": {
        lngName: "Русский",
        flag: "ru",
        changeLang: "Change language",
        legal: {
            linkName: "легальный",
            headline: "Только для внутреннего пользования!",
            text: "Данное приложение предназначено исключительно для использования внутри дилерской сети Porsche. Основной целью является обучение сотрудников дилерской сети Porsche.\n" +
                "Все тексты, изображения и прочая информация в этом приложении защищены авторским правом компании Dr. Ing. h.c. F. Porsche AG.\n" +
                "Данное приложение содержит виртуальную модель реальной системы. В связи с этим тексты, изображения и прочая информация могут отличаться от реальной системы. В приложении не объясняются особенности, обусловленные реалиями конкретных стран, а также особые конфигурации. Изменения в реальной системе не отображаются в приложении или отображаются лишь с задержкой.\n" +
                "Любое копирование, передача или другое использование разрешены только при условии предварительного получения письменного согласия Dr. Ing. h.c. F. Porsche AG.\n" +
                "Примечание:\n" +
                "Вся информация приводится по состоянию на ноябрь 2019 года.\n" +
                "© Dr. Ing. h.c. F. Porsche Aktiengesellschaft\n" +
                "Отдел VCQ / Повышение квалификации торгового персонала\n" +
                "Porscheplatz 1\n" +
                "D-70435 Stuttgart\n" +
                "www.porsche.com\n" +
                "Компания оставляет за собой право на изменения и не несет ответственности за ошибки в тексте.\n"
        },
        top: {
            headline: "Добро пожаловать в обучающий курс по PCM и Porsche Connect! ",
            text: "Коммуникационный центр PCM и службы Porsche Connect в Porsche Taycan имеют большое значение для использования автомобиля. Это приложение содержит всю наиболее важную информацию о PCM и соответствующих службах Porsche Connect в Taycan. Вы можете ознакомиться с системой в свободном режиме в разделе «Discover now!» или посмотреть подробные инструкции по конкретным вопросам в разделе «Learn more!»." +
                "Желаем вам успехов при прохождении обучающего курса по PCM и Porsche Connect!\nВаша команда Porsche Training"
        },
        bottom: [
            {
                icon: "hand point up outline",
                headline: "Discover now!",
                text: "Хотите ознакомиться с новым коммуникационным центром PCM или еще раз посмотреть определенные функции? В этом разделе вы можете свободно перемещаться по разным меню новой системы. Узнайте больше и ознакомьтесь со структурой и функциями нового пользовательского интерфейса на своем компьютере или планшете. "
            },
            {
                icon: "play circle",
                headline: "Learn more!",
                text: "У вас есть конкретные вопросы или вы хотите подробнее изучить PCM и службы Porsche Connect? В этом разделе вы найдете ответы на самые разные вопросы, рассортированные по категориям. При этом вы шаг за шагом ознакомитесь с коммуникационным центром PCM и службами Porsche Connect непосредственно в самой системе. Оптимальная подготовка к встречам с клиентами! "
            },
        ]
    },
    "zh-CN": {
        lngName: "简体中文",
        flag: "cn",
        changeLang: "Change language",
        legal: {
            linkName: "法律",
            headline: "仅供内部使用！",
            text: "此应用程序仅供保时捷经销组织内部使用。主要目的在于保时捷经销组织的培训。\n" +
                "此应用程序中的所有文字、图片和其他信息均受保时捷股份公司的版权保护。\n" +
                "此应用程序只是一个模拟版本。因此文字、图片和信息可能与实际产品有所不同。此应用程序不包含任何国家特定的特征或特有配置。实际产品的更改可能不会反映在模拟中，或者会延迟推出。\n" +
                "未经保时捷股份公司的事先书面同意，不得进行复制、复印或其他用途的使用。\n" +
                "提示：\n" +
                "本文全部内容根据截至 2019 年 11 月的情况编写。\n" +
                "© Dr. Ing. h.c. F. Porsche Aktiengesellschaft\n" +
                "VCQ / 销售培训部\n" +
                "Porscheplatz 1\n" +
                "D-70435 Stuttgart\n" +
                "www.porsche.com\n" +
                "保留修改和出错的权利。\n"
        },
        top: {
            headline: "欢迎进入 PCM 系统和保时捷智慧互联教程！ ",
            text: "保时捷 Taycan 上的 PCM 系统和保时捷智慧互联服务对于车辆的使用非常重要。借助此应用程序，您可了解 Taycan 上有关 PCM 系统以及相应保时捷智慧互联服务的基本主题。除了“立即发现！”区域内的免费试用之外，我们还通过“了解更多！”下的详细说明为您提供具体问题的支持。" +
                "祝您在使用 PCM 系统和保时捷智慧互联教程的过程中心情愉快！\n您的保时捷培训团队"
        },
        bottom: [
            {
                icon: "hand point up outline",
                headline: "立即发现！",
                text: "您是想要熟悉新的 PCM 系统，还是仅仅只是想再次查看某些功能？在此区域中，您可以自由点击新系统。环顾四周，在个人电脑或平板电脑上熟悉一下新界面的结构和功能。"
            },
            {
                icon: "play circle",
                headline: "了解更多！",
                text: "您是否有具体问题或者想详细了解 PCM 系统和保时捷智慧互联服务？在这一部分提供了按类别划分的问题和解答。由此您将直接在系统中逐一学习 PCM 系统和保时捷智慧互联服务。为您的客户谈话做完美准备。"
            },
        ]
    },
    "zh-TW": {
        lngName: "德語",
        flag: "tw",
        changeLang: "Change language",
        legal: {
            linkName: "法律",
            headline: "僅限內部使用！",
            text: "本應用程式僅限保時捷貿易組織內部使用。主要用途是保時捷貿易組織的資格培訓。\n" +
                "本應用程式中的所有文字、圖片及其他資訊皆受德國 Dr. Ing. h.c. F. Porsche AG 的版權保護。\n" +
                "本應用程式是模擬版。因此文字、圖片及資訊可能與實際產品有所差異。本應用程式不包含任何國家特定的特徵或特定配置。實際產品的變更可能不會呈現，或只會延遲呈現在模擬版中。\n" +
                "未經 Dr. Ing. h.c. F. Porsche AG 的事先書面許可，不得複製、轉載或用作其他用途。\n" +
                "注意事項：\n" +
                "所有資料來源根據截至 2019 年 11 月為止之現況。\n" +
                "© Dr. Ing. h.c. F. Porsche Aktiengesellschaft\n" +
                "VCQ 部門 / 銷售資格評定\n" +
                "Porscheplatz 1\n" +
                "D-70435 Stuttgart\n" +
                "www.porsche.com\n" +
                "保留變更與錯誤的權利。\n"
        },
        top: {
            headline: "歡迎來到 PCM 和保時捷互聯教學輔導課程！",
            text: "保時捷 Taycan 的 PCM 和保時捷互聯服務對於車輛使用十分重要。有了這個應用程式，您便能了解關於 Taycan 上 PCM 以及相關保時捷互聯服務的各種重要主題。除了可在「立即探索！」區內任意試用之外，您在「了解更多！」裡提出具體問題時，我們還會提供詳細說明支持。" +
                "好好享受保時捷互聯教學輔導課程吧！\n您的保時捷培訓團隊"
        },
        bottom: [
            {
                icon: "hand point up outline",
                headline: "立即探索！",
                text: "您想熟悉如何操作新的 PCM，或者想要不費力地再次查考某些功能嗎？在這個區裡，您可以在系統中隨意點選。四處看看，好好在您的電腦或平板上熟悉新介面的結構和功能吧。 "
            },
            {
                icon: "play circle",
                headline: "了解更多！",
                text: "您有具體的問題想問，或者想要更加了解 PCM 和保時捷互聯服務的使用細節嗎？在不同類別分類之下，有對各種問題的解答和解說。您由此便能直接在系統內一步步了解 PCM 和保時捷互聯服務。這可助您為客戶洽談做好完美準備。 "
            },
        ]
    },
}
export default sizeConditional()(connect((state: IState) => ({ state }))(PluginLandingPage));
